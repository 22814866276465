<template>
  <div class="Card">
    <div class="card-item" v-for="(item, index) in cardList" :key="index">
      <p>{{ item.title }}</p>
      <img :src="item.url" alt="" />
<!--      <p>{{ item.title2 }}</p>-->
      <div>{{ item.des }}</div>
      <el-button type="success" plain round @click="goToRegist">联系我们</el-button>
    </div>
  </div>
</template>

<script>
import icon from '@/assets/icon1.png'
import car from '@/assets/img/c1.png'
import car1 from '@/assets/img/c2.png'
import car2 from '@/assets/img/c3.png'
import car3 from '@/assets/img/c4.png'
import car4 from '@/assets/img/c5.png'
import car5 from '@/assets/img/c6.png'
import car6 from '@/assets/img/c7.png'
import car7 from '@/assets/img/c8.png'
import car8 from '@/assets/img/c9.png'
import car9 from '@/assets/img/c10.png'
import car10 from '@/assets/img/c11.png'
import car11 from '@/assets/img/c12.png'
import car12 from '@/assets/img/c13.png'
import car13 from '@/assets/img/c14.png'
export default {
  name: 'Card',
  data() {
    return {
      icon: icon,
      cardList: [
        {
          title: '国际短信',
          url: car,
          title2: '国际短信开发文档',
          des: '实现国际短信业务快速平台对接'
        },
        {
          title: '语音验证码',
          url: car1,
          title2: '语音验证码开发文档',
          des: '实现语音验证业务快速实现平台对接'
        },
        {
          title: '语音群呼',
          url: car2,
          title2: '语音群呼开发文档',
          des: '实现语音群呼业务快速实现平台对接'
        },
        {
          title: '语音通知',
          url: car3,
          title2: '语音通知开发文档',
          des: '实现语音坐席业务快速实现平台对接'
        },
        {
          title: '呼叫中心',
          url: car4,
          title2: '呼叫中心开发文档',
          des: '实现语音坐席业务快速实现平台对接'
        },
        {
          title: '全球虚拟号码',
          url: car5,
          title2: '全球虚拟号码开发文档',
          des: '实现虚拟号码业务快速平台对接'
        },
        {
          title: '邮件',
          url: car6,
          title2: '邮件开发文档',
          des: '实现邮件验证码业务快速平台对接'
        },
        {
          title: 'Verify',
          url: car7,
          title2: 'Verify',
          des: '集成短信、语音、邮件三大能力'
        },
        {
          title: '短链',
          url: car8,
          title2: '短链开发文档',
          des: '实现快速定向跳转至企业平台'
        },
        {
          title: '号码检测',
          url: car9,
          title2: '号码检测开发文档',
          des: '实现号码检测功能快速对接'
        },
        {
          title: 'WhatsApp',
          url: car10,
          title2: 'WhatsApp开发文档',
          des: '快速对接WhatsApp Business API'
        },
        {
          title: '一键登录(OTA)',
          url: car11,
          title2: '一键登录(OTA)开发文档',
          des: '实现快速安全一键登录'
        },
        {
          title: 'Viber',
          url: car12,
          title2: 'Viber开发文档',
          des: '快速对接Viber Business API'
        },
        {
          title: 'Super Messaging',
          url: car13,
          title2: 'Super Messaging 开发文档',
          des: '快速对接Super Messaging API'
        }
      ]
    }
  },
  methods: {
    goToRegist() {
      this.$router.push('/CONTACT')
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .Card {
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .card-item {
      width: 3rem;
      padding: 10px;
      height: 5.5rem;
      border: 1px solid #409eff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-radius: 30px;
      margin: 2%;
      text-align: center;
      img {
        width: 80px;
        height: 80px;
      }
      p {
        height: 80px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
        color: #333;
        font-size: 17px;
        font-weight: 600;
        // border-bottom: 2px solid rgba(133, 193, 0, 0.2);
      }
      div {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 19px;
        width: 90%;
        margin: 0 auto;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .btn {
        width: 86%;
        border: 2px solid #409eff;
        color: #409eff;
        font-size: 16px;
      }
    }
  }
  .Card:hover {
    cursor: pointer;
    // transform: scale(1.1);
  }
}
@media screen and  (min-width: 769px) {
  .Card {
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .card-item {
      width: 400px;
      padding: 10px;
      height: 400px;
      border: 1px solid #409eff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-radius: 30px;
      margin: 2%;
      text-align: center;
      img {
        width: 80px;
        height: 80px;
      }
      p {
        height: 80px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
        color: #333;
        font-size: 24px;
        font-weight: 600;
        border-bottom: 2px solid rgba(133, 193, 0, 0.2);
      }
      div {
        font-size: 16px;
        font-weight: 400;
        color: #666;
        line-height: 19px;
        width: 90%;
        margin: 0 auto;
        margin-top: 12px;
      }
      .btn {
        width: 80%;
        border: 2px solid #409eff;
        color: #409eff;
        font-size: 16px;
      }
    }
  }
  .Card:hover {
    cursor: pointer;
    // transform: scale(1.1);
  }
}
</style>
