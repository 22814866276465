<template>
  <div>
    <div class="helps">
      <div class="helpItem">
        <div class="help-left">
          <p>{{ $t('body.help.title1') }}</p>
          <div>
            {{ $t('body.help.desc1') }}
          </div>
        </div>
        <div class="help-right">
          <img :src="des1" alt="" />
        </div>
      </div>
    </div>

    <div class="helps" style="background-color: rgb(240,248,243)">
      <div class="helpItem">
        <div class="help-right">
          <img :src="des2" alt="" />
        </div>
        <div class="help-left">
          <p>{{ $t('body.help.title2') }}</p>
          <div>
            {{ $t('body.help.desc2') }}
          </div>
        </div>
      </div>
    </div>

    <div class="helps" style="padding-bottom: 0.2rem">
      <div class="helpItem">
        <div class="help-left">
          <p>{{ $t('body.help.title3') }}</p>
          <div>
            {{ $t('body.help.desc3') }}
          </div>
        </div>
        <div class="help-right">
          <img :src="des3" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import m1 from '@/assets/img/m1.png'
import m2 from '@/assets/img/m2.png'
import m3 from '@/assets/img/m3.jpg'
export default {
  data() {
    return {
      des1: m1,
      des2: m2,
      des3: m3
    }
  }
}
</script>

<style lang="scss" scoped>
.helps {
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .helpItem {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
    margin-bottom: 1%;
    .help-left {
      width: 45%;
      p {
        width: 100%;
        font-size: 32px;
        color: #171716;
        line-height: 38px;
        margin-bottom: 25px;
      }
      div {
        width: 100%;
        font-size: 16px;
        color: #464940;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }
    .help-right{
        width: 50%;
        height: 50%;
        img{
            width: 80%;
            height: 80%;
        }
    }
  }
}
</style>