<template>
    <div class="container">
        <div class="head">
            <Header />
        </div>
        <div class="content">
            <router-view />
        </div>
    </div>
</template>
<script>
import Header from './components/header.vue'
export default {
    components: { Header },
}
</script>
<style lang="scss" scoped>
.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
    .head {
        background-image: linear-gradient(
            #FFFFFF,
            #FFFFFF
        );
    }
    .content {
        flex: 1;
    }
    .section {
        width: 100%;
        height: 100%;
    }
}
</style>
