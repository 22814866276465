<template>
  <div class="text-main">
    <div class="text-left">
<!--      <div class="title">{{ activeData.title }}</div>-->
      <div class="title-des">
        {{ activeData.titleDes }}
      </div>
      <ul>
        <div v-for="(item, index) in activeData.textList" :key="index">
          <li><span class="dot"></span> {{ item.textTitle }}</li>
          <p>
            {{ item.textDes }}
          </p>
        </div>
      </ul>
      <el-button class="checkMore" type="success" round @click="goToBorrow"
        >{{ $t('body.p.btn') }}</el-button
      >
    </div>
    <div class="text-right">
      <img :src="imgList[key]" alt="" />
    </div>
  </div>
</template>

<script>
import des1 from '@/assets/img/sms.jpg'
import des2 from '@/assets/img/voice.jpg'
export default {
  props: {
    activeData: Object
  },
  data() {
    return {
      des1: des1,
      key: 'first1',
    }
  },
  watch: {
    activeData: {
      handler(newName) {
        const { key } = newName
        this.key = key
        console.log(key, 'newNamenewName')
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    goToBorrow() {
      this.$router.push('/CONTACT')
    }
  },
  computed: {
    imgList() {
      return {
        first1: des1,
        second2: des2,
        first: this.$t('body.p.img3'),
        second: this.$t('body.p.img4'),
        third: this.$t('body.p.img5')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and  (min-width: 769px){
  .text-main {
    transition: opacity 0.5s ease-in-out;
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-left {
      width: 45%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      .title {
        font-size: 24px;
        margin-bottom: 28px;
      }
      .title-des {
        font-size: 16px;
        font-weight: 400;
        color: #666;
        line-height: 22px;
        margin-bottom: 28px;
      }
      ul {
        li {
          font-size: 18px;
          font-weight: 500;
          color: #333;
          line-height: 36px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #666;
          line-height: 22px;
          margin-bottom: 28px;
        }
      }
    }
    .text-right {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dot::before {
    content: '\2022'; /* 使用 Unicode 编码表示圆点 */
    color: #1989fa; /* 小圆点的颜色 */
    display: inline-block;
    width: 0.5em; /* 设置宽度，控制圆点与文本的间距 */
    margin-right: 0.5em; /* 控制圆点与文本的间距 */
  }
  .checkMore {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .text-main {
    transition: opacity 0.5s ease-in-out;
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0.3rem;
    .text-left {
      width: 92%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      .title {
        font-size: 24px;
        margin-bottom: 28px;
      }
      .title-des {
        font-size: 16px;
        font-weight: 400;
        color: #666;
        line-height: 22px;
        margin-bottom: 28px;
      }
      ul {
        li {
          font-size: 18px;
          font-weight: 500;
          color: #333;
          line-height: 36px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #666;
          line-height: 22px;
          margin-bottom: 28px;
        }
      }
    }
    .text-right {
      display: none;
      transform: scale(0.6);
      img {
        width: auto;
        height: auto;
      }
    }
  }
  .dot::before {
    content: '\2022'; /* 使用 Unicode 编码表示圆点 */
    color: #1989fa; /* 小圆点的颜色 */
    display: inline-block;
    width: 0.5em; /* 设置宽度，控制圆点与文本的间距 */
    margin-right: 0.5em; /* 控制圆点与文本的间距 */
  }
  .checkMore {
    width: 80%;
  }
}
</style>