<template>
  <div class="complaints" >
    <Header></Header>
    <div class="forms" >
      <p>{{ $t('cp.title') }}</p>
      <div class="form-des" :class="isLoading ? 'is-disable' : ''">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item :label="$t('cp.f.l1')" prop="name">
            <el-input
              v-model="ruleForm.name"
              :placeholder="$t('cp.f.p1')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('cp.f.l2')" prop="phone">
            <el-input
              :placeholder="$t('cp.f.p2')"
              v-model="ruleForm.phone"
              class="input-with-select"
            >
              <el-select
                v-model="select"
                filterable
                slot="prepend"
              >
                <el-option
                  v-for="(item, index) in countriesList"
                  :key="index"
                  :label="item.code + ' ' + item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('cp.f.l3')" prop="email">
            <el-input
              v-model="ruleForm.email"
              :placeholder="$t('cp.f.p3')"
            ></el-input>
          </el-form-item>

          <el-form-item :label="$t('cp.f.l4')" prop="company">
            <el-input
              v-model="ruleForm.company"
              :placeholder="$t('cp.f.p4')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('cp.f.l5')" prop="jobTitle">
            <el-input
              v-model="ruleForm.jobTitle"
              :placeholder="$t('cp.f.p5')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('cp.f.l6')" prop="interestProducts">
            <el-select
              class="pro-select"
              multiple
              v-model="ruleForm.interestProducts"
              :placeholder="$t('cp.f.p6')"
            >
              <el-option
                v-for="(item, index) in productList"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cp.f.l7')" prop="content">
            <el-input
              class="textarea-des"
              type="textarea"
              :placeholder="$t('cp.f.p7')"
              v-model="ruleForm.content"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="isLoading"
              class="sub-btn"
              type="success"
              @click="submitForm('ruleForm')"
              >{{ $t('cp.f.btn') }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from '@/layout/components/header.vue'
import Bottom from '@/layout/components/Bottom.vue'
export default {
  name: 'Contact',
  components: { Header, Bottom },
  data() {
    return {
      isLoading: false,
      interestProduct: [],
      select: '+86',
      ruleForm: {
        phone: '',
        name: '',
        email: '',
        company: '',
        jobTitle: '',
        interestProducts: [],
        content: ''
      },
      rules: {
        name: [{ required: true, message: '', trigger: 'blur' }],
        phone: [{ required: true, message: '', trigger: 'blur' }],
        email: [
          { required: true, message: '', trigger: 'blur' },
          {
            type: 'email',
            message: 'invalid',
            trigger: ['blur', 'change']
          }
        ],
        company: [
          { required: true, message: '', trigger: 'blur' }
        ],
        content:[
          { required: true, message: '', trigger: 'blur' }
        ],
        jobTitle: [{ required: true, message: '', trigger: 'blur' }],
        interestProducts: [
          { required: true, message: '', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    productList() {
      return this.$t('cp.productList')
    },
    countriesList() {
      return this.$t('cp.countries')
    }
  },
  mounted() {
    // console.log(this.countriesList)
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.ruleForm.phone = this.select + this.ruleForm.phone
          this.ruleForm.interestProduct =
            this.ruleForm.interestProducts.join(',')
          const { ...params } = this.ruleForm
          // console.log(params)
          this.$axios
            .post('/traffic/api/customerInquire/add', params)
            .then((res) => {
              this.isLoading = false
              if (res.data.retCode === '000000') {
                this.$message({
                  message: 'success',
                  type: 'success'
                })
                for (let key in this.ruleForm) {
                  if (key == 'interestProducts') {
                    this.ruleForm[key] = []
                  } else {
                    this.ruleForm[key] = ''
                  }
                }
              } else {
                this.$message({
                  message: res.data.retMessage,
                  type: 'error'
                })
              }
            })
            .catch((error) => {
              this.isLoading = false
              this.$message({
                message: error.message,
                type: 'error'
              })
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disable{
  pointer-events: none;
  cursor: none;
}
@media only screen and (max-width: 768px) {
  .main {
    background-color: aliceblue;

    h3 {
      font-size: 44px;
      font-weight: 700;
      line-height: 86px;
      padding: 72px 0 36px;
      text-align: center;
      color: black !important;
    }
  }
  .complaints {
    overflow: auto;
    height: 100vh;
    overflow-x: hidden;
    background-color: rgb(248, 250, 255);
    background-image: url('../assets/movback.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
    .forms {
      height: 800px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 3%;
      .form-des {
        width: 95%;
        margin-right: 7%;
      }
      p {
        font-size: 20px;
        margin-bottom: 20px;
        color: white;
      }
    }
  }
  ::v-deep .el-textarea__inner {
    height: 160px !important;
  }
  ::v-deep .el-select {
    width: 110px;
  }
  ::v-deep .el-form-item__label {
    font-size: 16px;
    color: white;
  }
  .sub-btn {
    width: 50%;
  }
  .pro-select {
    width: 100%;
  }
}
@media screen and  (min-width: 769px){
  .main {
    background-color: aliceblue;
    h3 {
      font-size: 44px;
      font-weight: 700;
      line-height: 86px;
      padding: 72px 0 36px;
      text-align: center;
      color: black !important;
    }
  }
  .complaints {
    overflow: auto;
    height: 100vh;
    overflow-x: hidden;
    //background-color: rgba(230, 242, 234, 1);
    background-image: url('../assets/form.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
    .forms {
      height: 800px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 3%;
      .form-des {
        width: 41%;
      }
      p {
        font-size: 20px;
        margin-bottom: 20px;
        color: white;
      }
    }
  }
  ::v-deep .el-textarea__inner {
    height: 160px !important;
  }
  ::v-deep .el-select {
    width: 150px;
  }
  ::v-deep .el-form-item__label {
    font-size: 16px;
    color: white;
  }
  .sub-btn {
    width: 50%;
  }
  .pro-select {
    width: 100%;
  }
}
</style>