<template>
  <div class="tab-main">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane :label="$t('body.p.name1')" name="first1">
        <TabText :activeData="first1"></TabText>
      </el-tab-pane>
      <el-tab-pane :label="$t('body.p.name2')" name="second2">
        <TabText :activeData="second2"></TabText>
      </el-tab-pane>
      <el-tab-pane :label="$t('body.p.name3')" name="first">
        <TabText :activeData="first"></TabText>
      </el-tab-pane>
<!--      <el-tab-pane :label="$t('body.p.name4')" name="second">-->
<!--        <TabText :activeData="second"></TabText>-->
<!--      </el-tab-pane>-->
      <el-tab-pane :label="$t('body.p.name5')" name="third">
        <TabText :activeData="third"></TabText>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import TabText from '@/layout/components/tabText.vue'
export default {
  components: { TabText },
  data() {
    return {
      activeName: 'first1',
    }
  },
  methods: {
  },
  computed: {
    first1() {
      return {
        key:'first1',
        titleDes: this.$t('body.p.title1'),
        textList: [
          {
            textTitle: this.$t('body.p.t1.title'),
            textDes: this.$t('body.p.t1.desc'),
          },
          {
            textTitle: this.$t('body.p.t2.title'),
            textDes: this.$t('body.p.t2.desc'),
          },
          {
            textTitle: this.$t('body.p.t3.title'),
            textDes: this.$t('body.p.t3.desc'),
          },
        ]
      }
    },
    second2() {
      return {
        key:'second2',
        titleDes: this.$t('body.p.title2'),
        textList: [
          {
            textTitle: this.$t('body.p.t21.title'),
            textDes: this.$t('body.p.t21.desc'),
          },
          {
            textTitle: this.$t('body.p.t22.title'),
            textDes: this.$t('body.p.t22.desc'),
          },
          {
            textTitle: this.$t('body.p.t23.title'),
            textDes: this.$t('body.p.t23.desc'),
          },
        ]
      }
    },
    first() {
      return {
        key:'first',
        titleDes: this.$t('body.p.title3'),
        textList: [
          {
            textTitle: this.$t('body.p.t21.title'),
            textDes: this.$t('body.p.t21.desc'),
          },
        ]
      }
    },
    second() {
      return {
        key:'second',
        titleDes: this.$t('body.p.title4'),
        textList: [
          {
            textTitle: this.$t('body.p.t41.title'),
            textDes: this.$t('body.p.t41.desc'),
          },
          {
            textTitle: this.$t('body.p.t42.title'),
            textDes: this.$t('body.p.t42.desc'),
          },
          {
            textTitle: this.$t('body.p.t43.title'),
            textDes: this.$t('body.p.t43.desc'),
          },
        ]
      }
    },
    third() {
      return {
        key:'third',
        titleDes: this.$t('body.p.title5'),
        textList: [
          {
            textTitle: this.$t('body.p.t51.title'),
            textDes: this.$t('body.p.t51.desc'),
          },
          {
            textTitle: this.$t('body.p.t52.title'),
            textDes: this.$t('body.p.t52.desc'),
          },
          {
            textTitle: this.$t('body.p.t53.title'),
            textDes: this.$t('body.p.t53.desc'),
          },
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 600px) {
  .tab-main {
    margin: 0 auto;
  }
  ::v-deep .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  ::v-deep .el-tabs__item {
    font-size: 18px;
  }
  ::v-deep .el-tabs__header {
    border-bottom: none !important;
  }
}
</style>