<template>
  <div class="HOME">
<!--    <div style="margin-top:4.3%">-->
<!--      <Carousel :dataList="dataListHome"></Carousel>-->
<!--    </div>-->
    <div class="descList-style">
      <div class="des-item" v-for="(item, index) in desList" :key="index">
        <img :src="item.url" alt="" />
        <div class="des-title">{{ item.title }}</div>
        <div class="des-text">{{ item.text }}</div>
      </div>
    </div>
    <div style="margin-top: 0.2rem">
      <HelpItem></HelpItem>
    </div>
    <div class="main">
      <h3>{{ $t('body.t1') }}</h3>
      <div class="product-tabs">
        <Tabs></Tabs>
      </div>
    </div>
    <div class="youshi">
      <h3>{{ $t('body.t2') }}</h3>
      <Company></Company>
    </div>
    <div class="contact">
      <div class="c-left">
        <img :src="contactImg" alt="" />
      </div>
      <div class="c-right">
        <p>{{ $t('contact.title') }}</p>
        <div class="bottom2-des">
          <p>{{ $t('contact.desc') }}</p>
<!--          <el-button type="primary" @click="goToRegist" round>{{ $t('contact.btn') }}</el-button>-->
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
// import Carousel from '@/layout/components/Carousel.vue'
import Bottom from '@/layout/components/Bottom.vue'
import HelpItem from '@/layout/components/helpItem.vue'
import Tabs from '@/layout/components/tabs.vue'
import Company from '@/layout/components/compList.vue'
import p1 from '@/assets/img/p1.png'
import p2 from '@/assets/img/p2.png'
import p3 from '@/assets/img/p3.png'
// import p4 from '@/assets/img/p4.png'
import citys from '@/assets/alldata.jpg'
import contactImg from '@/assets/img/contact.jpg'
export default {
  name: 'HOME',
  components: { Bottom, Tabs, HelpItem, Company },
  data() {
    return {
      contactImg: contactImg,
      citys: citys,
    }
  },
  methods: {
    goToRegist() {
      this.$router.push('/CONTACT')
    },
    routerClick(data) {
      let name = data
      let anchor = document.getElementById(name)
      anchor.scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start' // 上边框与视窗顶部平齐
      })
    }
  },
  computed: {
    dataListHome() {
      return [
        {
          url: this.$t('body.banner')
        }
      ]
    },
    desList() {
      return [
        {
          url: p1,
          title: this.$t('body.ys.title1'),
          text: this.$t('body.ys.desc1')
        },
        {
          url: p2,
          title: this.$t('body.ys.title2'),
          text: this.$t('body.ys.desc2')
        },
        {
          url: p3,
          title: this.$t('body.ys.title3'),
          text: this.$t('body.ys.desc3')
        },
        // {
        //   url: p4,
        //   title: this.$t('body.ys.title4'),
        //   text: this.$t('body.ys.desc4')
        // }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 768px) 
{
  .HOME {
    background-color: rgba(230, 242, 234, 1);
  }
  .main {
    background-color: rgba(255, 255, 255, 1);
    padding-bottom: 0.2rem;
    h3 {
      font-size: 44px;
      font-weight: 700;
      line-height: 50px;
      padding: 40px 0 36px;
      text-align: center;
      color: black !important;
    }
  }
  .youshi {
    margin-top: 0.2rem;
    h3 {
      font-size: 44px;
      font-weight: 700;
      line-height: 50px;
      padding: 40px 0 36px;
      text-align: center;
      color: black !important;
    }
  }

  .contact {
    background-color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
    margin-bottom: 1%;
    .c-left{
      width: 40%;
      height: 50%;
      img{
        width: 80%;
        height: 80%;
      }
    }

    .c-right {
      width: 40%;
      background-color: rgba(112, 199, 142, 1);
      border-radius: 10px;
      margin: 0.3rem;
      height: 200px;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        line-height: 60px;
        margin-bottom: 20px;
      }
      .bottom2-des {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          display: none;
          width: 100%;
          text-align: center;
          color: #fff;
          line-height: 21px;
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 12px;
        }
        div {
          width: 100%;
          color: #fff;
          line-height: 16px;
          text-align: center;
          font-size: 14px;
          margin-bottom: 16px;
        }
      }
    }
    img {
      width: 100%;
      margin: 0.2rem
    }
  }

  .descList-style {
    width: 100%;
    height: 100%;
    margin-top: 0.2rem;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    background-color: rgb(248, 250, 266);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .des-item {
      width: 4rem;
      height: 100%;
      line-height: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5%;
      img {
        margin-top: 0.2rem;
        width: 56px;
        height: 56px;
      }
      .des-title {
        margin-top: 0.1rem;
        font-size: 0.35rem;
      }
      .des-title2 {
        font-size: 20px;
        color: #409eff;
      }
      .des-text {
        font-size: 14px;
        color: rgba(128, 128, 128, 1);
      }
    }
  }
  .citys {
    height: 300px;
    text-align: center;
    img {
      height: 100%;
      transform: scaleX(2);
    }
  }
  .product-tabs {
    width: 82%;
    // height: 560px;
    // background-color: antiquewhite;
    margin: 0 auto;
  }
  .bottom2 {
    height: 6rem;
    background-image: url('../assets/abouts.jpeg');
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12%;
    p {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 42px;
      font-weight: 400;
      line-height: 60px;
      margin-bottom: 20px;
    }
    .bottom2-des {
      display: flex;
      align-items: center;
      flex-direction: column;
      p {
        width: 100%;
        text-align: center;
        color: #fff;
        line-height: 21px;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 12px;
      }
      div {
        width: 100%;
        color: #fff;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
  }
}
@media screen and  (min-width: 769px) {
  .HOME {
    background-color: rgba(230, 242, 234, 1);
  }
  .main {
     background-color: rgba(255, 255, 255, 1);
     padding-bottom: 0.2rem;
    h3 {
      font-size: 44px;
      font-weight: 700;
      line-height: 50px;
      padding: 40px 0 36px;
      text-align: center;
      color: black !important;
    }
  }
  .youshi {
    margin-top: 0.2rem;
    h3 {
      font-size: 44px;
      font-weight: 700;
      line-height: 50px;
      padding: 40px 0 36px;
      text-align: center;
      color: black !important;
    }
  }

  .contact {
    background-color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
    margin-bottom: 1%;
    .c-left{
      width: 50%;
      height: 50%;
      img{
        width: 80%;
        height: 80%;
      }
    }

    .c-right {
      width: 45%;
      background-color: rgba(112, 199, 142, 1);
      border-radius: 10px;
      margin: 0.3rem;
      height: 350px;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 42px;
        font-weight: 400;
        line-height: 60px;
        margin-bottom: 20px;
      }
      .bottom2-des {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          width: 100%;
          text-align: center;
          color: #fff;
          line-height: 21px;
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 12px;
        }
        div {
          width: 100%;
          color: #fff;
          line-height: 16px;
          text-align: center;
          font-size: 14px;
          margin-bottom: 16px;
        }
      }
    }
    img {
      width: 100%;
      margin: 0.2rem
    }
  }

  .descList-style {
    width: 100%;
    height: 100%;
    margin-top: 0.9rem;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    background-color: rgb(248, 250, 266);
    display: flex;
    justify-content: center;
    .des-item {
      width: 500px;
      height: 100%;
      line-height: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      img {
        width: 56px;
        height: 56px;
      }
      .des-title {
        margin-top: 0.1rem;
        font-size: 20px;
      }
      .des-title2 {
        font-size: 20px;
        color: #409eff;
      }
      .des-text {
        font-size: 14px;
        color: rgba(128, 128, 128, 1);
      }
    }
  }
  .citys {
    height: 300px;
    text-align: center;
    img {
      height: 100%;
      transform: scaleX(2);
    }
  }
  .product-tabs {
    width: 70%;
    // height: 560px;
    // background-color: antiquewhite;
    margin: 0 auto;
  }
}

</style>

