<template>
  <div class="HEADER" id="Home" >
    <div class="content" id="HEADER" :class="{ sticky: isSticky }">
      <div class="logo" @click="routerClick('/HOME')">
        <img class="logoImg" :src="logo" alt="" />
         <label style="margin-left: 0.2rem;color: rgba(112, 199, 142, 1)">GALAXIA</label>
      </div>
      <ul class="nav">
        <li :class="{ active: activeItem === '/HOME' }"  @click="routerClick('/HOME')">
          {{$t('header.nav1')}}
        </li>
        <li v-show="selectedOption === 'zh'" :class="{ active: activeItem === '/DOCUMENTS' }" @click="routerClick('/DOCUMENTS')">
          {{$t('header.nav2')}}
        </li>
<!--        <li class="li-lang">-->
<!--          <el-select class="langs" v-model="selectedOption" size="small"-->
<!--                     @change="changeLanguage"-->
<!--                     placeholder="Language">-->
<!--            <el-option value="zh" label="中文"></el-option>-->
<!--            <el-option value="en" label="English"></el-option>-->
<!--          </el-select>-->
<!--        </li>-->
<!--        <li :class="{ active: activeItem === '/CONTACT' }"  @click="routerClick('/CONTACT')">-->
<!--          <el-button type="success" round size="mini">{{$t('header.nav3')}}</el-button>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</template>
<script>
import logo from '@/assets/img/logo.jpg'
export default {
  name: 'HEADER',
  data() {
    return {
      isSticky:false,
      logo: logo,
      selectedOption: this.$store.state.langs,
      activeItem: this.$route.path // 用于记录当前活动项
    }
  },
  methods: {
    routerClick(data) {
        this.$router.push(data)
    },
    changeLanguage(val) {
      this.$nextTick(() => {
        this.$store.commit('setLangs', val)
        localStorage.setItem('lang', val)
        this.$i18n.locale = val
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .content {
    height: 0.8rem;
    width: 101%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // padding-right: 0.65rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    background: white;
    position: fixed;
    z-index: 100;
  .logo {
    height: 100%;
    font-size: 20px;
    font-weight: 600;
    .logoImg {
      height: 50%;
    }
    label {
      font-size: 8px;
    }
  }
  .nav {
    display: flex;
    line-height: 44px;
    
    margin-left: -3%;
    // margin-right: 57%;
    .li-lang {
      width: 100px
    }
    li {
      padding: 0.1rem;
      font-size: 8px;
      font-weight: 500;
      cursor: pointer;
      &.active {
        color: #5f4fcb; // 设置活动项的文本颜色为绿色
      }
      img{
        width: 100%;
        height: 100%;
      }
      .langs {

      }
      input {
        height: 20px;
        line-height: 20px;
        font-size: 10px;
      }
    }
    li:hover{
      color:#5f4fcb ;
    }
  }
}
  .el-input--small {
    font-size: 10px;
  }
}
@media screen and  (min-width: 769px){
  .content {
    height: 0.8rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right: 0.65rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    background: white;
    position: fixed;
    z-index: 100;
  .logo {
    height: 100%;
    margin-left: 9%;
    margin-top: 0%;
    font-size: 20px;
    font-weight: 600;
    .logoImg {
      height: 100%;
    }
  }
  .nav {
    display: flex;
    line-height: 44px;
    // margin-right: 57%;
    .li-lang {
      width: 250px;
    }
    li {
      padding: 0.45rem;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      &.active {
        color: #5f4fcb; // 设置活动项的文本颜色为绿色
      }
      img{
        width: 100%;
        height: 100%;
      }
      .langs {
        width: 50%;
      }
    }
    li:hover{
      color:#5f4fcb ;
    }
  }
}
/* 设置吸顶时的样式 */
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* 添加其他样式 */
}

/* 设置placeholder的样式 */
.placeholder {
  display: none;
}
}
</style>
