import banner1 from '@/assets/img/banner1.jpg'
import des3 from '@/assets/tab1.svg'
import des4 from '@/assets/tab7.svg'
import des5 from '@/assets/tab2.svg'
export default {
    header: {
        nav1: '首页',
        nav2: '产品',
        nav3: '联系我们',
    },
    body: {
        banner: banner1,
        t1: '主推产品',
        t2: '我们的优势',
        ys: {
            title1: '全球资源互联',
            desc1: '链接全球1000+合作伙伴运营商',
            title2: '产品品类丰富',
            desc2: '涵盖全品类CPaaS、SaaS云通信产品',
            title3: '研发实力雄厚',
            desc3: '精通云通信底层技术以及AI开发能力',
            title4: '多语言本地化',
            desc4: '业务可覆盖全球220多个国家和地区'
        },
        help: {
            title1: '国际短信',
            desc1: '通过短信API对接，发送全球短信（国际短信验证码、国际营销短信、国际短信通知）；或使用平台发送个性化的营销活动短信。',
            title2: '国际语音',
            desc2: '国际呼入、国际呼出、国际云呼叫中心，VoIP解决方案帮助节约成本，提升效率。',
            title3: '即时通讯工具API',
            desc3: '提供全球热门即时通讯工具的API对接，如WhatsApp, Twitter, Instagram Messaging等，方便用户触达企业。',
        },
        p: {
            btn: '查看详情',
            name1: '短信',
            title1: '国际短信通道覆盖全球200多个国家和地区，可应用于国际短信验证码、国际营销短信、国际短信通知等应用场景。',
            t1: {
                title: '国际短信',
                desc: '专属直连通道，快速稳定，可应对高并发需求'
            },
            t2: {
                title: 'SMPP和HTTP协议',
                desc: '支持SMPP及HTTP协议，可通过API接口发送或平台发送'
            },
            t3: {
                title: '中国区专属技术服务团队',
                desc: '365天24小时全天为您服务'
            },
            name2: '语音',
            title2: '通过我们的平台在客户偏好的即时通讯工具上，如WhatsApp, Apple Messages for Business, Twitter, RCS等，提供客户支持、发送营销活动及交易提醒等。',
            t21: {
                title: 'API',
                desc: '通过一个API即可对接时下所有流行的即时通讯工具'
            },
            t22: {
                title: '官方授权',
                desc: 'Meta (WhatsApp) Business API官方授权服务商'
            },
            t23: {
                title: '一站式快速解决',
                desc: '即时通讯工具辅以平台其他强大功能，为企业提供一站式的互动解决方案'
            },
            name3: 'WhatsApp',
            img3: des3,
            title3: 'GALAXIA是WhatsApp官方授权的商业解决方案服务商（Business Solution Provider，简称BSP），基于WhatsApp能力，提供商业化即时通信服务，为企业高效触达用户提供灵活选择，助力企业精准链接全球用户。',
            t31: {
                title: 'WhatsApp Business Platform',
                desc: '企业可将WhatsApp Business Platform集成到自研的客户管理平台、营销服务平台以及客服对话平台中，一键接入，触达全球。'
            },
            name4: 'IWLink',
            img4: des4,
            title4: 'GALAXIA自研的一款海外数字营销系统，无需系统集成、开箱即用。一站式集成WhatsApp、Viber、国际短信、国际语音等社媒与通信能力，精准高效触达海外用户，智能呼叫、智能应答客户咨询。助力跨境卖家、出海游戏等企业高效完成营销获客、客户服务和客户管理，构建私域流量。',
            t41: {
                title: '客户服务',
                desc: '支持WhatsApp、Viber会话功能，可与客户实时开展消息对话，及时解决客户咨询与客诉。'
            },
            t42: {
                title: '私域营销',
                desc: '通过WhatsApp、Viber、SMS将营销信息精准传递给消费者，高效完成营销转化。'
            },
            t43: {
                title: '智能呼叫中心',
                desc: '融合传统呼叫中心和AI智能、SaaS技术能力，帮助企业快速组建海外智能呼叫中心系统。'
            },
            name5: '全球虚拟号码',
            img5: des5,
            title5: '具备呼入与呼出的语音通信能力以及上下行短信通知能力，可实现在任何地方呼叫和接听各国电话，并能显示来电号码归属地，且不需要支付国际长途费。',
            t51: {
                title: '全球会议号码',
                desc: '用于跨国会议系统的语音电话接入等场景。'
            },
            t52: {
                title: '隐私号码（PNS）',
                desc: '用于海外网约车、外卖、物流配送等场景。'
            },
            t53: {
                title: '全球虚拟号码',
                desc: '用于海外公司品牌宣传、客户服务与维护、业务对接等场景。'
            },
        },
        ys2: {
            title1: '服务覆盖',
            desc1: '覆盖国家和地区',
            title2: '短信',
            desc2: '年消息收发总量',
            count2: '35亿+',
            title3: '语音',
            desc3: '年通话分钟总数',
            count3: '8亿+',
            title4: '系统稳定性',
            desc4: '稳定运行时间'
        },
    },
    contact: {
        title: '开始为您提供更优质的服务体验',
        desc: '欢迎使用GALAXIA-CLOUD，开启您的云上之旅！',
        btn: '联系我们',
        phone: '联系电话',
        email: '联系邮箱'
    },
    cp: {
        title: '联系商务，我们将为您提供更优质的服务体验',
        f: {
            l1: '姓名',
            p1: '请输入您的姓名',
            l2: '手机号码',
            p2: '请输入您的手机号',
            l3: '邮箱',
            p3: '请输入您的邮箱',
            l4: '公司名称',
            p4: '请输入您的公司名称',
            l5: '职称',
            p5: '请输入您的职称',
            l6: '产品',
            p6: '请选择你感兴趣的产品',
            l7: '需求',
            p7: '请简述您的需求，我们的专属顾问将与您联系',
            btn: '提交'
        },
        productList: [
            '国际短信验证码',
            '国际营销短信',
            '国际通知短信',
            '邮件验证码',
            'WhatsApp Business',
            'Viber Business',
            '语音验证码',
            '语音群呼',
            '语音通知',
            '全球虚拟号码',
            '号码检测',
            '全球会议号码',
            '智能语音机器人',
            'IWlink',
            '呼叫中心系统',
            '短链接'
        ],
        countries: [
            {"code": "+86", "name": "中国"},
            {"code": "+93", "name": "阿富汗"},
            {"code": "+355", "name": "阿尔巴尼亚"},
            {"code": "+213", "name": "阿尔及利亚"},
            {"code": "+1684", "name": "美属萨摩亚"},
            {"code": "+376", "name": "安道尔"},
            {"code": "+244", "name": "安哥拉"},
            {"code": "+1264", "name": "安圭拉"},
            {"code": "+1268", "name": "安提瓜和巴布达岛"},
            {"code": "+54", "name": "阿根廷"},
            {"code": "+374", "name": "亚美尼亚"},
            {"code": "+297", "name": "阿鲁巴"},
            {"code": "+61", "name": "澳大利亚"},
            {"code": "+43", "name": "奥地利"},
            {"code": "+994", "name": "阿塞拜疆"},
            {"code": "+1242", "name": "巴哈马"},
            {"code": "+973", "name": "巴林"},
            {"code": "+880", "name": "孟加拉国"},
            {"code": "+1246", "name": "巴巴多斯"},
            {"code": "+375", "name": "白俄罗斯"},
            {"code": "+32", "name": "比利时"},
            {"code": "+501", "name": "伯利兹"},
            {"code": "+229", "name": "贝宁"},
            {"code": "+1441", "name": "百慕大群岛"},
            {"code": "+975", "name": "不丹"},
            {"code": "+591", "name": "玻利维亚"},
            {"code": "+387", "name": "波斯尼亚和黑塞哥维那"},
            {"code": "+267", "name": "博茨瓦纳"},
            {"code": "+55", "name": "巴西"},
            {"code": "+673", "name": "文莱达鲁萨兰国"},
            {"code": "+359", "name": "保加利亚"},
            {"code": "+226", "name": "布基纳法索"},
            {"code": "+257", "name": "布隆迪"},
            {"code": "+855", "name": "柬埔寨"},
            {"code": "+237", "name": "喀麦隆"},
            {"code": "+238", "name": "佛得角"},
            {"code": "+1345", "name": "开曼群岛"},
            {"code": "+236", "name": "中非共和国"},
            {"code": "+235", "name": "乍得"},
            {"code": "+56", "name": "智利"},
            {"code": "+57", "name": "哥伦比亚"},
            {"code": "+269", "name": "科摩罗"},
            {"code": "+242", "name": "刚果"},
            {"code": "+243", "name": "刚果金"},
            {"code": "+682", "name": "库克群岛"},
            {"code": "+506", "name": "哥斯达黎加"},
            {"code": "+225", "name": "科特迪瓦"},
            {"code": "+385", "name": "克罗地亚"},
            {"code": "+53", "name": "古巴"},
            {"code": "+357", "name": "塞浦路斯"},
            {"code": "+420", "name": "捷克共和国"},
            {"code": "+45", "name": "丹麦"},
            {"code": "+246", "name": "迭戈加西亚"},
            {"code": "+253", "name": "吉布提"},
            {"code": "+1767", "name": "多米尼克"},
            {"code": "+593", "name": "厄瓜多尔"},
            {"code": "+20", "name": "埃及"},
            {"code": "+503", "name": "萨尔瓦多"},
            {"code": "+240", "name": "赤道几内亚"},
            {"code": "+372", "name": "爱沙尼亚"},
            {"code": "+500", "name": "福克兰群岛"},
            { "code": "+298", "name": "法罗群岛" },
            { "code": "+679", "name": "斐济" },
            { "code": "+358", "name": "芬兰" },
            { "code": "+33", "name": "法国" },
            { "code": "+594", "name": "法属圭亚那" },
            { "code": "+689", "name": "法属波利尼西亚" },
            { "code": "+241", "name": "加蓬" },
            { "code": "+220", "name": "冈比亚" },
            { "code": "+995", "name": "格鲁吉亚" },
            { "code": "+49", "name": "德国" },
            { "code": "+233", "name": "加纳" },
            { "code": "+350", "name": "直布罗陀" },
            { "code": "+30", "name": "希腊" },
            { "code": "+299", "name": "格陵兰岛" },
            { "code": "+1473", "name": "格林纳达" },
            { "code": "+590", "name": "瓜德罗普岛" },
            { "code": "+1671", "name": "关岛" },
            { "code": "+502", "name": "危地马拉" },
            { "code": "+224", "name": "几内亚" },
            { "code": "+245", "name": "几内亚比绍" },
            { "code": "+592", "name": "圭亚那" },
            { "code": "+509", "name": "海地" },
            { "code": "+504", "name": "洪都拉斯" },
            { "code": "+852", "name": "中国香港" },
            { "code": "+36", "name": "匈牙利" },
            { "code": "+354", "name": "冰岛" },
            { "code": "+91", "name": "印度" },
            { "code": "+62", "name": "印度尼西亚" },
            { "code": "+98", "name": "伊朗" },
            { "code": "+964", "name": "伊拉克" },
            { "code": "+353", "name": "爱尔兰" },
            { "code": "+972", "name": "以色列" },
            { "code": "+39", "name": "意大利" },
            { "code": "+1876", "name": "牙买加" },
            { "code": "+81", "name": "日本" },
            { "code": "+962", "name": "约旦" },
            { "code": "+7", "name": "俄罗斯" },
            { "code": "+254", "name": "肯尼亚" },
            { "code": "+383", "name": "科索沃" },
            { "code": "+965", "name": "科威特" },
            { "code": "+996", "name": "吉尔吉斯斯坦" },
            { "code": "+856", "name": "老挝" },
            { "code": "+371", "name": "拉脱维亚" },
            { "code": "+961", "name": "黎巴嫩" },
            { "code": "+266", "name": "莱索托" },
            { "code": "+231", "name": "利比里亚" },
            { "code": "+218", "name": "利比亚" },
            { "code": "+423", "name": "列支敦士登" },
            { "code": "+370", "name": "立陶宛" },
            { "code": "+352", "name": "卢森堡" },
            { "code": "+853", "name": "中国澳门" },
            { "code": "+389", "name": "北马其顿" },
            { "code": "+261", "name": "马达加斯加" },
            { "code": "+265", "name": "马拉维" },
            { "code": "+60", "name": "马来西亚" },
            { "code": "+960", "name": "马尔代夫" },
            { "code": "+223", "name": "马里" },
            { "code": "+356", "name": "马耳他" },
            { "code": "+596", "name": "马提尼克" },
            { "code": "+222", "name": "毛里塔尼亚" },
            { "code": "+230", "name": "毛里求斯" },
            { "code": "+262", "name": "马约特岛" },
            { "code": "+52", "name": "墨西哥" },
            { "code": "+373", "name": "摩尔多瓦" },
            { "code": "+976", "name": "蒙古" },
            { "code": "+382", "name": "黑山" },
            { "code": "+1664", "name": "蒙特塞拉特岛" },
            { "code": "+212", "name": "摩洛哥" },
            { "code": "+258", "name": "莫桑比克" },
            { "code": "+95", "name": "缅甸" },
            { "code": "+264", "name": "纳米比亚" },
            { "code": "+674", "name": "瑙鲁" },
            { "code": "+977", "name": "尼泊尔" },
            { "code": "+31", "name": "荷兰" },
            { "code": "+599", "name": "荷属安的列斯群岛" },
            { "code": "+687", "name": "新喀里多尼亚" },
            { "code": "+64", "name": "新西兰" },
            { "code": "+505", "name": "尼加拉瓜" },
            { "code": "+227", "name": "尼日尔" },
            { "code": "+234", "name": "尼日利亚" },
            { "code": "+672", "name": "诺福克岛" },
            { "code": "+1670", "name": "北马里亚纳群岛" },
            { "code": "+47", "name": "挪威" },
            { "code": "+968", "name": "阿曼" },
            { "code": "+92", "name": "巴基斯坦" },
            { "code": "+680", "name": "帕劳" },
            { "code": "+970", "name": "巴勒斯坦领土" },
            { "code": "+507", "name": "巴拿马" },
            { "code": "+675", "name": "巴布亚新几内亚" },
            { "code": "+595", "name": "巴拉圭" },
            { "code": "+51", "name": "秘鲁" },
            { "code": "+63", "name": "菲律宾" },
            { "code": "+48", "name": "波兰" },
            { "code": "+351", "name": "葡萄牙" },
            { "code": "+974", "name": "卡塔尔" },
            { "code": "+262", "name": "留尼汪" },
            { "code": "+40", "name": "罗马尼亚" },
            { "code": "+7", "name": "哈萨克斯坦" },
            { "code": "+250", "name": "卢旺达" },
            { "code": "+685", "name": "萨摩亚" },
            { "code": "+378", "name": "圣马力诺" },
            { "code": "+239", "name": "圣多美和普林西比" },
            { "code": "+966", "name": "沙特阿拉伯" },
            { "code": "+221", "name": "塞内加尔" },
            { "code": "+381", "name": "塞尔维亚" },
            { "code": "+248", "name": "塞舌尔" },
            { "code": "+232", "name": "塞拉利昂" },
            { "code": "+65", "name": "新加坡" },
            { "code": "+421", "name": "斯洛伐克" },
            { "code": "+386", "name": "斯洛文尼亚" },
            { "code": "+252", "name": "索马里" },
            { "code": "+27", "name": "南非" },
            { "code": "+82", "name": "韩国" },
            { "code": "+211", "name": "南苏丹" },
            { "code": "+34", "name": "西班牙" },
            { "code": "+94", "name": "斯里兰卡" },
            { "code": "+1869", "name": "圣基茨和尼维斯" },
            { "code": "+1758", "name": "圣卢西亚" },
            { "code": "+508", "name": "圣彼埃尔和密克隆岛" },
            { "code": "+1784", "name": "圣文森特和格林纳丁斯" },
            { "code": "+249", "name": "苏丹" },
            { "code": "+597", "name": "苏里南" },
            { "code": "+46", "name": "瑞典" },
            { "code": "+41", "name": "瑞士" },
            { "code": "+963", "name": "叙利亚" },
            { "code": "+886", "name": "中国台湾" },
            { "code": "+992", "name": "塔吉克斯坦" },
            { "code": "+255", "name": "坦桑尼亚" },
            { "code": "+66", "name": "泰国" },
            { "code": "+670", "name": "东帝汶" },
            { "code": "+228", "name": "多哥" },
            { "code": "+676", "name": "汤加" },
            { "code": "+1868", "name": "特立尼达和多巴哥" },
            {"code": "+216", "name": "突尼斯"},
            {"code": "+90", "name": "土耳其"},
            {"code": "+993", "name": "土库曼斯坦"},
            {"code": "+1649", "name": "特克斯和凯科斯群岛"},
            {"code": "+256", "name": "乌干达"},
            {"code": "+380", "name": "乌克兰"},
            {"code": "+971", "name": "阿拉伯联合酋长国"},
            {"code": "+44", "name": "英国"},
            {"code": "+1", "name": "美国"},
            {"code": "+598", "name": "乌拉圭"},
            {"code": "+998", "name": "乌兹别克斯坦"},
            {"code": "+678", "name": "瓦努阿图"},
            {"code": "+58", "name": "委内瑞拉"},
            {"code": "+84", "name": "越南"},
            {"code": "+1284", "name": "英属维京群岛"},
            {"code": "+1340", "name": "美属维珍群岛"},
            {"code": "+967", "name": "也门"},
            {"code": "+260", "name": "赞比亚"},
            {"code": "+263", "name": "津巴布韦"},
            {"code": "+377", "name": "摩纳哥"},
            {"code": "+251", "name": "埃塞俄比亚"},
            {"code": "+1787", "name": "波多黎各"},
            {"code": "+268", "name": "斯威士兰"},
            {"code": "+1809", "name": "多明尼加共和国"},
            {"code": "+291", "name": "厄立特里亚"},
            {"code": "+1", "name": "加拿大"},
            {"code": "+681", "name": "瓦利斯和福图纳岛"},
            {"code": "+683", "name": "纽埃"},
            {"code": "+677", "name": "所罗门群岛"},
            {"code": "+290", "name": "圣赫勒拿岛"},
            {"code": "+691", "name": "密克罗尼西亚联邦"},
            {"code": "+44", "name": "英属根西岛"},
            {"code": "+686", "name": "基里巴斯"},
            {"code": "+850", "name": "朝鲜"},
            {"code": "+692", "name": "马绍尔群岛共和国"},
            {"code": "+688", "name": "图瓦卢"},
            {"code": "+379", "name": "梵蒂冈城"},
            {"code": "+599", "name": "库拉索"},
            {"code": "+4", "name": "泽西"}
        ]
    }
}