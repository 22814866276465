<template>
  <div class="company">
    <div class="com-item" v-for="(item, index) in comList" :key="index">
      <p style="margin: 0.2rem">{{ item.title }}</p>
      <img :src="item.url" alt="" />
      <p style="color: #409eff">{{ item.desc }}</p>
      <div class="des">{{ item.des }}</div>
    </div>
  </div>
</template>

<script>
import s1 from '@/assets/img/s1.jpg'
import s2 from '@/assets/img/s2.jpg'
import s3 from '@/assets/img/s3.jpg'
import s4 from '@/assets/img/s4.jpg'
export default {
  data() {
    return {
      icon1: s1,
    }
  },
  computed: {
    comList() {
      return [
        // {
        //   url: s1,
        //   title: this.$t('body.ys2.title1'),
        //   desc: "200+",
        //   des: this.$t('body.ys2.desc1'),
        // },
        {
          url: s2,
          title: this.$t('body.ys2.title2'),
          desc: this.$t('body.ys2.count2'),
          des: this.$t('body.ys2.desc2'),
        },
        {
          url: s3,
          title: this.$t('body.ys2.title3'),
          desc: this.$t('body.ys2.count3'),
          des: this.$t('body.ys2.desc3'),
        },
        {
          url: s4,
          title: this.$t('body.ys2.title4'),
          desc: "99.95%",
          des: this.$t('body.ys2.desc4'),
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and  (min-width: 769px){
  .company {
    display: flex;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 4%;
    .com-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 250px;
      background-color: aliceblue;
      border-radius: 25px;
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 4%;
      }
      p {
        font-size: 18px;
        font-weight: 600;
      }
      .des {
        margin: 5%;
        font-size: 16px;
        line-height: 28px;
        color: darkgray;
      }
    }
  }
}
// 移动
@media only screen and (max-width: 768px) {
  .company {
    display: flex;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 2%;
    flex-direction: column;
    .com-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 250px;
      background-color: aliceblue;
      border-radius: 25px;
          margin-bottom: 5%;
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 4%;
      }
      p {
        font-size: 18px;
        font-weight: 600;
      }
      .des {
        margin: 5%;
        font-size: 16px;
        line-height: 28px;
        color: darkgray;
      }
    }
  }
}
</style>