import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import zh from './zh'
import en from './en'
Vue.use(VueI18n)
const messages = {
    zh: {
        ...zh,
    },
    en: {
        ...en,
    }
}
const i18n = new VueI18n({
    messages,
    locale: store.state.langs,
})
export default i18n