import banner2 from '@/assets/img/banner2.jpg'
import des3 from '@/assets/img/desc3.jpg'
import des4 from '@/assets/img/desc4.jpg'
import des5 from '@/assets/img/desc5.jpg'
export default {
    header: {
        nav1: 'HOME',
        nav2: 'PRODUCT',
        nav3: 'CONTACT US',
    },
    body: {
        banner: banner2,
        t1: 'Main Product',
        t2: 'Our Advantages',
        ys: {
            title1: 'Domestic Resource Connectivity',
            desc1: 'Connected with 1000+ Domestic carrier partners.',
            title2: 'Diverse Product Categories',
            desc2: 'Full range of SAAS and CPAAS products.',
            title3: 'Large R&D investment',
            desc3: 'R&D in cloud communication underlying technology and security technology.',
            title4: 'Localization Language Mastery',
            desc4: 'Localization coverage in 200+ countries local languages.'
        },
        p: {
            btn: 'View Details',
            name1: 'SMS',
            title1: 'Domestic SMS channels covering 200+ countries and regions, applicable to various scenarios like Domestic SMS verification codes, Domestic marketing messages, and Domestic SMS notifications.',
            t1: {
                title: 'Domestic SMS',
                desc: 'Exclusive direct connection channels, fast and stable to handle high concurrency demands'
            },
            t2: {
                title: 'SMPP and HTTP protocols supported',
                desc: 'SMPP and HTTP protocols supported, sending via API or platform'
            },
            t3: {
                title: 'Dedicated technical service team',
                desc: 'Dedicated technical service team in China available 24/7, 365 days.'
            },
            name2: 'VOICE',
            title2: 'Through our platform, provide customer support, send marketing activities, and transaction alerts on customers\' preferred instant messaging tools like WhatsApp, Apple Messages for Business, Twitter, RCS, etc.',
            t21: {
                title: 'API',
                desc: 'Integrate with all popular instant messaging tools through one API'
            },
            t22: {
                title: 'Officially Authorized',
                desc: 'Officially authorized Meta (WhatsApp) Business API service provider'
            },
            t23: {
                title: 'One-stop Fast Solution',
                desc: 'Combine instant messaging tools with other powerful platform features to provide businesses with a one-stop interactive solution'
            },
            name3: 'WhatsApp',
            img3: des3,
            title3: 'GALAXIA is an official Business Solution Provider (BSP) authorized by WhatsApp. Based on WhatsApp capabilities, it provides commercial instant communication services, offering businesses flexible choices to efficiently reach users and connect with Domestic audiences',
            t31: {
                title: 'WhatsApp Business Platform',
                desc: 'Businesses can integrate the WhatsApp Business Platform into their self-developed customer management platform, marketing service platform, and customer service dialogue platform, enabling Domestic reach with a single click.'
            },
            name4: 'IWLink',
            img4: des4,
            title4: 'A self-developed overseas digital marketing system by GALAXIA, no need for system integration, ready to use. Integrated capabilities include WhatsApp, Viber, Domestic SMS, Domestic voice, etc., efficiently reaching overseas users, intelligent calling, and answering customer inquiries. Facilitates efficient marketing, customer service, and customer management for cross-border sellers, Domestic game companies, etc., building private domain traffic.',
            t41: {
                title: 'Customer Service',
                desc: 'Supports WhatsApp, Viber conversation functions, engaging in real-time message conversations with customers to promptly address inquiries and complaints'
            },
            t42: {
                title: 'Private Domain Marketing',
                desc: 'Precision delivery of marketing messages to consumers through WhatsApp, Viber, SMS, efficiently completing marketing conversions'
            },
            t43: {
                title: 'Intelligent Call Center',
                desc: 'Integrating traditional call centers with AI intelligence and SaaS technology capabilities, helping companies quickly establish overseas intelligent call center systems'
            },
            name5: 'Domestic Virtual Numbers',
            img5: des5,
            title5: 'Have voice communication capabilities for both incoming and outgoing calls, as well as SMS notification capabilities, enabling calls and receiving calls from anywhere Domesticly. Displays the caller\'s location, and there is no need to pay Domestic long-distance fees',
            t51: {
                title: 'Domestic Conference Numbers',
                desc: 'Used for scenarios such as voice phone access in multinational conference systems'
            },
            t52: {
                title: 'Privacy Numbers (PNS)',
                desc: 'Used in overseas ride-sharing, food delivery, logistics, etc'
            },
            t53: {
                title: 'Domestic Virtual Numbers',
                desc: 'Used for overseas company brand promotion, customer service and maintenance, business connections, etc'
            },
        },
        help: {
            title1: 'Domestic SMS',
            desc1: 'Connect through SMS API to send Domestic messages (Domestic SMS verification codes, Domestic marketing messages, Domestic SMS notifications), or use the platform to send personalized marketing activity messages.',
            title2: 'Domestic Voice and Agents',
            desc2: 'Domestic inbound, outbound, and Domestic cloud call centers. VoIP solutions help save costs and improve efficiency.',
            title3: 'Instant Messaging Tools API',
            desc3: 'Provide API integration for popular Domestic instant messaging tools, such as WhatsApp, Twitter, Instagram Messaging, etc., making it convenient for users to engage with businesses.',
        },
        ys2: {
            // title1: 'Service Coverage',
            // desc1: 'Covers countries and regions',
            title2: 'SMS',
            desc2: 'Annual total volume of messages sent and received',
            count2: '3.5 billion +',
            title3: 'Voice',
            desc3: 'Annual total minutes of calls',
            count3: '800 million +',
            title4: 'System Stability',
            desc4: 'Stable operating time'
        },
    },
    contact: {
        title: 'Start experiencing a better service with GALAXIA-CLOUD',
        desc: 'Welcome to embark on your journey in the cloud!',
        btn: 'contact us',
        phone: 'phone',
        email: 'email'
    },
    cp: {
        title: 'Contact us, we will provide you with a better service experience',
        f: {
            l1: 'Name',
            p1: 'Please input your name',
            l2: 'Phone',
            p2: 'Please input your phone number',
            l3: 'Email',
            p3: 'Please input your Email',
            l4: 'Company',
            p4: 'Please input your company name',
            l5: 'Job Title',
            p5: 'Please input your job title',
            l6: 'Product',
            p6: 'Please select the product you are interested in',
            l7: 'Content',
            p7: 'Please outline your requirements and one of our dedicated consultants will contact you',
            btn: 'Save'
        },
        productList: [
            'OTP SMS',
            'Marketing SMS',
            'Notification SMS',
            'Email OTP',
            'WhatsApp Business',
            'Viber for Buisiness',
            'Voice OTP',
            'Group Voice Notification',
            'Voice Notification',
            'Flash Call Verification',
            'Direct Inward Dialing Number',
            'Number Checking',
            'Domestic Conference Number',
            'NXCallbot',
            'NXLink',
            'Cloud Attendant System',
            'Short link'
        ],
        countries: [
            {"code": "+86", "name": "CHINA"},
            {"code": "+93", "name": "AFGHANISTAN"},
            {"code": "+355", "name": "ALBANIA"},
            {"code": "+213", "name": "ALGERIA"},
            {"code": "+1684", "name": "AMERICAN SAMOA"},
            {"code": "+376", "name": "ANDORRA"},
            {"code": "+244", "name": "ANGOLA"},
            {"code": "+1264", "name": "ANGUILLA"},
            {"code": "+1268", "name": "ANTIGUA &amp; BARBUDA"},
            {"code": "+54", "name": "ARGENTINA"},
            {"code": "+374", "name": "ARMENIA"},
            {"code": "+297", "name": "ARUBA"},
            {"code": "+61", "name": "AUSTRALIA"},
            {"code": "+43", "name": "AUSTRIA"},
            {"code": "+994", "name": "AZERBAIJAN"},
            {"code": "+1242", "name": "BAHAMAS"},
            {"code": "+973", "name": "BAHRAIN"},
            {"code": "+880", "name": "BANGLADESH"},
            {"code": "+1246", "name": "BARBADOS"},
            {"code": "+375", "name": "BELARUS"},
            {"code": "+32", "name": "BELGIUM"},
            {"code": "+501", "name": "BELIZE"},
            {"code": "+229", "name": "BENIN"},
            {"code": "+1441", "name": "BERMUDA"},
            {"code": "+975", "name": "BHUTAN"},
            {"code": "+591", "name": "BOLIVIA"},
            {"code": "+387", "name": "BOSNIA &amp; HERZEGOVINA"},
            {"code": "+267", "name": "BOTSWANA"},
            {"code": "+55", "name": "BRAZIL"},
            {"code": "+673", "name": "BRUNEI DARUSSALAM"},
            {"code": "+359", "name": "BULGARIA"},
            {"code": "+226", "name": "BURKINA FASO"},
            {"code": "+257", "name": "BURUNDI"},
            {"code": "+855", "name": "CAMBODIA"},
            {"code": "+237", "name": "CAMEROON"},
            {"code": "+238", "name": "CAPE VERDE"},
            {"code": "+1345", "name": "CAYMAN ISLANDS"},
            {"code": "+236", "name": "CENTRAL AFRICAN REPUBLIC"},
            {"code": "+235", "name": "CHAD"},
            {"code": "+56", "name": "CHILE"},
            {"code": "+57", "name": "COLOMBIA"},
            {"code": "+269", "name": "COMOROS"},
            {"code": "+242", "name": "CONGO"},
            {"code": "+243", "name": "CONGO D.R."},
            {"code": "+682", "name": "COOK ISLANDS"},
            {"code": "+506", "name": "COSTA RICA"},
            {"code": "+225", "name": "COTE DIVOIRE"},
            {"code": "+385", "name": "CROATIA"},
            {"code": "+53", "name": "CUBA"},
            {"code": "+357", "name": "CYPRUS"},
            {"code": "+420", "name": "CZECH REPUBLIC"},
            {"code": "+45", "name": "DENMARK"},
            {"code": "+246", "name": "DIEGO GARCIA"},
            {"code": "+253", "name": "DJIBOUTI"},
            {"code": "+1767", "name": "DOMINICA"},
            {"code": "+593", "name": "ECUADOR"},
            {"code": "+20", "name": "EGYPT"},
            {"code": "+503", "name": "EL SALVADOR"},
            {"code": "+240", "name": "EQUATORIAL GUINEA"},
            {"code": "+372", "name": "ESTONIA"},
            {"code": "+500", "name": "FALKLAND ISLANDS"},
            {"code": "+298", "name": "FAROE ISLANDS"},
            {"code": "+679", "name": "FIJI"},
            {"code": "+358", "name": "FINLAND"},
            {"code": "+33", "name": "FRANCE"},
            {"code": "+594", "name": "FRENCH GUIANA"},
            {"code": "+689", "name": "FRENCH POLYNESIA"},
            {"code": "+241", "name": "GABON"},
            {"code": "+220", "name": "GAMBIA"},
            {"code": "+995", "name": "GEORGIA"},
            {"code": "+49", "name": "GERMANY"},
            {"code": "+233", "name": "GHANA"},
            {"code": "+350", "name": "GIBRALTAR"},
            {"code": "+30", "name": "GREECE"},
            {"code": "+299", "name": "GREENLAND"},
            {"code": "+1473", "name": "GRENADA"},
            {"code": "+590", "name": "GUADELOUPE"},
            {"code": "+1671", "name": "GUAM"},
            {"code": "+502", "name": "GUATEMALA"},
            {"code": "+224", "name": "GUINEA"},
            {"code": "+245", "name": "GUINEA-BISSAU"},
            {"code": "+592", "name": "GUYANA"},
            {"code": "+509", "name": "HAITI"},
            {"code": "+504", "name": "HONDURAS"},
            {"code": "+852", "name": "HONG KONG"},
            {"code": "+36", "name": "HUNGARY"},
            {"code": "+354", "name": "ICELAND"},
            {"code": "+91", "name": "INDIA"},
            {"code": "+62", "name": "INDONESIA"},
            {"code": "+98", "name": "IRAN"},
            {"code": "+964", "name": "IRAQ"},
            {"code": "+353", "name": "IRELAND"},
            {"code": "+972", "name": "ISRAEL"},
            {"code": "+39", "name": "ITALY"},
            {"code": "+1876", "name": "JAMAICA"},
            {"code": "+81", "name": "JAPAN"},
            {"code": "+962", "name": "JORDAN"},
            {"code": "+7", "name": "RUSSIA"},
            {"code": "+254", "name": "KENYA"},
            {"code": "+383", "name": "KOSOVO"},
            {"code": "+965", "name": "KUWAIT"},
            {"code": "+996", "name": "KYRGYZSTAN"},
            {"code": "+856", "name": "LAOS"},
            {"code": "+371", "name": "LATVIA"},
            {"code": "+961", "name": "LEBANON"},
            {"code": "+266", "name": "LESOTHO"},
            {"code": "+231", "name": "LIBERIA"},
            {"code": "+218", "name": "LIBYA"},
            {"code": "+423", "name": "LIECHTENSTEIN"},
            {"code": "+370", "name": "LITHUANIA"},
            {"code": "+352", "name": "LUXEMBOURG"},
            {"code": "+853", "name": "MACAU"},
            {"code": "+389", "name": "North Macedonia"},
            {"code": "+261", "name": "MADAGASCAR"},
            {"code": "+265", "name": "MALAWI"},
            {"code": "+60", "name": "MALAYSIA"},
            {"code": "+960", "name": "MALDIVES"},
            {"code": "+223", "name": "MALI"},
            {"code": "+356", "name": "MALTA"},
            {"code": "+596", "name": "MARTINIQUE"},
            {"code": "+222", "name": "MAURITANIA"},
            {"code": "+230", "name": "MAURITIUS"},
            {"code": "+262", "name": "Mayotte"},
            {"code": "+52", "name": "MEXICO"},
            {"code": "+373", "name": "MOLDOVA"},
            {"code": "+976", "name": "MONGOLIA"},
            {"code": "+382", "name": "MONTENEGRO"},
            {"code": "+1664", "name": "MONTSERRAT"},
            {"code": "+212", "name": "MOROCCO"},
            {"code": "+258", "name": "MOZAMBIQUE"},
            {"code": "+95", "name": "MYANMAR"},
            {"code": "+264", "name": "NAMIBIA"},
            {"code": "+674", "name": "NAURU"},
            {"code": "+977", "name": "NEPAL"},
            {"code": "+31", "name": "NETHERLANDS"},
            {"code": "+599", "name": "NETHERLANDS ANTILLES"},
            {"code": "+687", "name": "NEW CALEDONIA"},
            {"code": "+64", "name": "NEW ZEALAND"},
            {"code": "+505", "name": "NICARAGUA"},
            {"code": "+227", "name": "NIGER"},
            {"code": "+234", "name": "NIGERIA"},
            {"code": "+672", "name": "NORFOLK ISLAND"},
            {"code": "+1670", "name": "NORTHERN MARIANA ISLANDS"},
            {"code": "+47", "name": "NORWAY"},
            {"code": "+968", "name": "OMAN"},
            {"code": "+92", "name": "PAKISTAN"},
            {"code": "+680", "name": "PALAU"},
            {"code": "+970", "name": "PALESTINIAN TERRITORY"},
            {"code": "+507", "name": "PANAMA"},
            {"code": "+675", "name": "PAPUA NEW GUINEA"},
            {"code": "+595", "name": "PARAGUAY"},
            {"code": "+51", "name": "PERU"},
            {"code": "+63", "name": "PHILIPPINES"},
            {"code": "+48", "name": "POLAND"},
            {"code": "+351", "name": "PORTUGAL"},
            {"code": "+974", "name": "QATAR"},
            {"code": "+262", "name": "REUNION"},
            {"code": "+40", "name": "ROMANIA"},
            {"code": "+7", "name": "Kazakhstan"},
            {"code": "+250", "name": "RWANDA"},
            {"code": "+685", "name": "SAMOA"},
            {"code": "+378", "name": "SAN MARINO"},
            {"code": "+239", "name": "SAO TOME &amp; PRINCIPE"},
            {"code": "+966", "name": "SAUDI ARABIA"},
            {"code": "+221", "name": "SENEGAL"},
            {"code": "+381", "name": "SERBIA"},
            {"code": "+248", "name": "SEYCHELLES"},
            {"code": "+232", "name": "SIERRA LEONE"},
            {"code": "+65", "name": "SINGAPORE"},
            {"code": "+421", "name": "SLOVAKIA"},
            {"code": "+386", "name": "SLOVENIA"},
            {"code": "+252", "name": "SOMALIA"},
            {"code": "+27", "name": "SOUTH AFRICA"},
            {"code": "+82", "name": "SOUTH KOREA"},
            {"code": "+211", "name": "SOUTH SUDAN"},
            {"code": "+34", "name": "SPAIN"},
            {"code": "+94", "name": "SRI LANKA"},
            {"code": "+1869", "name": "ST KITTS &amp; NEVIS"},
            {"code": "+1758", "name": "ST LUCIA"},
            {"code": "+508", "name": "ST PIERRE &amp; MIQUELON"},
            {"code": "+1784", "name": "ST VINCENT &amp; THE GRENADINES"},
            {"code": "+249", "name": "SUDAN"},
            {"code": "+597", "name": "SURINAME"},
            {"code": "+46", "name": "SWEDEN"},
            {"code": "+41", "name": "SWITZERLAND"},
            {"code": "+963", "name": "SYRIA"},
            {"code": "+886", "name": "TAIWAN"},
            {"code": "+992", "name": "TAJIKISTAN"},
            {"code": "+255", "name": "TANZANIA"},
            {"code": "+66", "name": "THAILAND"},
            {"code": "+670", "name": "TIMOR-LESTE"},
            {"code": "+228", "name": "TOGO"},
            {"code": "+676", "name": "TONGA"},
            {"code": "+1868", "name": "TRINIDAD &amp; TOBAGO"},
            {"code": "+216", "name": "TUNISIA"},
            {"code": "+90", "name": "TURKEY"},
            {"code": "+993", "name": "TURKMENISTAN"},
            {"code": "+1649", "name": "TURKS &amp; CAICOS ISLANDS"},
            {"code": "+256", "name": "UGANDA"},
            {"code": "+380", "name": "UKRAINE"},
            {"code": "+971", "name": "UNITED ARAB EMIRATES"},
            {"code": "+44", "name": "UNITED KINGDOM"},
            {"code": "+1", "name": "UNITED STATES"},
            {"code": "+598", "name": "URUGUAY"},
            {"code": "+998", "name": "UZBEKISTAN"},
            {"code": "+678", "name": "VANUATU"},
            {"code": "+58", "name": "VENEZUELA"},
            {"code": "+84", "name": "VIETNAM"},
            {"code": "+1284", "name": "VIRGIN ISLANDS BRITISH"},
            {"code": "+1340", "name": "VIRGIN ISLANDS U.S."},
            {"code": "+967", "name": "YEMEN"},
            {"code": "+260", "name": "ZAMBIA"},
            {"code": "+263", "name": "ZIMBABWE"},
            {"code": "+377", "name": "MONACO"},
            {"code": "+251", "name": "Ethiopia"},
            {"code": "+1787", "name": "Puerto Rico"},
            {"code": "+268", "name": "Swaziland"},
            {"code": "+1809", "name": "DOMINICAN REPUBLIC"},
            {"code": "+291", "name": "ERITREA"},
            {"code": "+1", "name": "CANADA"},
            {"code": "+681", "name": "Wallis &amp; Futuna"},
            {"code": "+683", "name": "Niue"},
            {"code": "+677", "name": "Solomon Islands"},
            {"code": "+290", "name": "St. Helena"},
            {"code": "+691", "name": "Micronesia"},
            {"code": "+44", "name": "Guernsey"},
            {"code": "+686", "name": "Kiribati"},
            {"code": "+850", "name": "North Korea"},
            {"code": "+692", "name": "Marshall Islands"},
            {"code": "+688", "name": "Tuvalu"},
            {"code": "+379", "name": "Vatican City"},
            {"code": "+599", "name": "Curaçao"},
            {"code": "+44", "name": "Jersey"}
        ]
    }
}