import Vue from 'vue'
import VueRouter from 'vue-router'
import HOME from '@/views/HOME.vue'
import LayOut from '@/layout'
import Documents from "@/views/Documents";
import Contact from "@/views/Contact";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: LayOut,
    redirect: '/HOME',
    children: [
      {
        path: '/HOME',
        name: 'HOME',
        component: HOME
      }
    ]
  },
  {
    path: '/DOCUMENTS',
    name: 'Documents',
    component:Documents
  },
  {
    path: '/CONTACT',
    name: 'CONTACT',
    component:Contact
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
