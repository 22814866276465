import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/style/common.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'lib-flexible/flexible'
import i18n from './lang/index'

Vue.config.productionTip = false;
axios.defaults.baseURL = 'https://apple-manager.datakarma.co'; // 替换为你实际的 API 接口地址
// 可以在这里设置其他的默认配置，例如请求超时时间、请求头等
Vue.prototype.$axios = axios;

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
