import { render, staticRenderFns } from "./HOME.vue?vue&type=template&id=e2fc51b6&scoped=true&"
import script from "./HOME.vue?vue&type=script&lang=js&"
export * from "./HOME.vue?vue&type=script&lang=js&"
import style0 from "./HOME.vue?vue&type=style&index=0&id=e2fc51b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2fc51b6",
  null
  
)

export default component.exports