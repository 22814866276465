<template>
  <div class="complaints">
    <div>
      <Header></Header>
    </div>
    <div class="banner">
      <div class="banner-text">
        <p>使用 GALAXIA API</p>
        <div>支持HTTPS，使用API轻松访问</div>
      </div>
    </div>
    <div class="main">
      <h3>开发文档</h3>
    </div>
    <Card></Card>
    <div class="details">
      <Bottom></Bottom>
    </div>
  </div>
</template>

<script>
import Header from '@/layout/components/header.vue'
import Bottom from '@/layout/components/Bottom.vue'
import Card from '@/layout/components/Card.vue'
export default {
  name: 'Documents',
  components: { Header, Bottom, Card },
  data() {
    return {}
  },
  methods: {
    goToRegist() {
      this.$router.push('/CONTACT')
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .main {
    // background-color: #ff9f00;
    h3 {
      font-size: 36px;
      font-weight: 500;
      line-height: 10px;
      padding: 36px 0 60px;
      text-align: center;
      color: black !important;
    }
  }
  .complaints {
    background-color: rgba(230, 242, 234, 1);
    height: 100vh;
    overflow: auto;
    .banner {
      height: 5rem;
      background-image: url('../assets/more.jpg');
      background-repeat: no-repeat;
      background-size: 100%;
      position: relative;
      .banner-text {
        width: 44%;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 48%;
        top: 40%;
        .regist-btn {
          width: 60%;
          margin-top: 5%;
        }
        p {
          color: #fff;
          // line-height: 52px;
          font-size: 0.4rem;
          font-weight: 500;
          animation-delay: 0s;
        }
        div {
          font-size: 12px;
          font-weight: 400;
          color: #fff;
          line-height: 28px;
          animation-delay: 0.35s;
          margin-top: 3px;
        }
      }
    }
  }
}
@media screen and  (min-width: 769px) {
  .main {
    // background-color: #ff9f00;
    h3 {
      font-size: 36px;
      font-weight: 500;
      line-height: 10px;
      padding: 40px 0 36px;
      text-align: center;
      color: black !important;
    }
  }
  .complaints {
    background-color: rgba(230, 242, 234, 1);
    height: 100vh;
    overflow: auto;
    .banner {
      height: 6rem;
      background-image: url('../assets/more.jpg');
      background-repeat: no-repeat;
      background-size: 100%;
      position: relative;
      .banner-text {
        width: 39%;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 48%;
        top: 46%;
        .regist-btn {
          width: 30%;
          margin-top: 5%;
        }
        p {
          color: #fff;
          line-height: 52px;
          font-size: 44px;
          font-weight: 500;
          animation-delay: 0s;
        }
        div {
          font-size: 20px;
          font-weight: 400;
          color: #fff;
          line-height: 28px;
          animation-delay: 0.35s;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>