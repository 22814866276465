<template>
  <div>
    <div class="bottom">
      <div class="bottom-main">
        <div>GALAXIA GIGA S.A. DE C.V.</div>
        <div>Calle Cosala 9, Col. Hipódromo Condesa 06170  Ciudad de México, CDMX</div>
        <div>5548623909</div>
<!--        <div class="bottom-tex">-->
<!--          <el-link-->
<!--            :underline="false"-->
<!--            style="color: white; font-size: 20px; margin: 0.2rem"-->
<!--            icon="el-icon-phone"-->
<!--            >{{ $t('contact.phone') }}：+8613810062935</el-link-->
<!--          >-->
<!--          <el-link-->
<!--            :underline="false"-->
<!--            style="color: white; font-size: 20px"-->
<!--            icon="el-icon-message"-->
<!--            >{{ $t('contact.email') }}：pengren0429@gmail.com</el-link-->
<!--          >-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    routerClick(data) {
      this.$router.push(data)
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and  (min-width: 769px) {
  .bottom {
    height: 150px;
    background-color: #0d1623;
    .bottom-main {
      height: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 20px;
      line-height: 40px;
      span {
        display: inline-block;
        width: 150px;
        text-align: center;
      }
      .nav {
        span {
          cursor: pointer;
        }
      }
    }
  }
  .nav span:hover {
    color: #b744e2;
  }
}
@media only screen and (max-width: 768px) {
  .bottom {
    height: 150px;
    background-color: #0d1623;
    .bottom-main {
      height: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 20px;
      line-height: 40px;
      span {
        display: inline-block;
        width: 150px;
        text-align: center;
      }
      .nav {
        span {
          cursor: pointer;
        }
      }

      .bottom-tex{
        text-align: center;
      }
    }
  }
  .nav span:hover {
    color: #b744e2;
  }
}
</style>